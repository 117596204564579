import React from 'react';
import './App.scss';

import appsFromServer from './api/applications';
import propsFromServer from './api/properties';
import categoriesFromServer from './api/categories';
import { FullApp } from './types/application';
import { PropertiesList } from './components/PropertiesList';
import { ApplicationsList } from './components/ApplicationsList';
import { CategoriesList } from './components/CategoriesList';

const fullAppTable: FullApp[] = appsFromServer.map((app) => {
  const property = propsFromServer.find((prop) => prop.id === app.propertyId) || null;
  const category = property ? categoriesFromServer.find((cat) => cat.id === property.categoryId) || null : null;

  return {
    ...app,
    property,
    category,
  };
});

export const App: React.FC = () => {

  return (
    <div className="section">
      <div className="container">
        <h1 className="title">React Application Examples</h1>

        <div className="block">
          <nav className="panel">
            <p className="panel-heading">Filters</p>

            <CategoriesList categories={categoriesFromServer}/>

            <div className="panel-block">
              <p className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  className="input"
                  placeholder="Search"
                  // value="qwe"
                />

                <span className="icon is-left">
                  <i className="fas fa-search" aria-hidden="true" />
                </span>

                <span className="icon is-right">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    type="button"
                    className="delete"
                  />
                </span>
              </p>
            </div>

            <PropertiesList properties={propsFromServer} />

            <div className="panel-block">
              <a
                href="#/"
                className="button is-link is-outlined is-fullwidth"

              >
                Reset all filters
              </a>
            </div>
          </nav>
        </div>

        <ApplicationsList applications={fullAppTable} />

      </div>
    </div>
)};


import React from 'react';
import cn from 'classnames';
import { Property } from '../../types/property';


interface Props {
  properties: Property[];
}

export const PropertiesList: React.FC<Props> = ({ properties }) => {
  
  return (
    <div className="panel-block is-flex-wrap-wrap">
      <a
        href="#/"
        className="button is-success mr-6 is-outlined"
      >
        All
      </a>    

      {properties.map((property) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          key={property.id}
          className={cn('button mr-2 my-1', {
            'is-info': false,
          })}
        >
          {property.title}
        </a>
      ))}
    </div>
)};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    categoryId: 1,
    id: 1,
    title: 'React Hooks',
  },
  {
    categoryId: 1,
    id: 2,
    title: 'Lifecycle Methods',
  },
  {
    categoryId: 1,
    id: 3,
    title: 'State',
  },
  {
    categoryId: 1,
    id: 4,
    title: 'React Router',
  },
];

import React from 'react';
import { Category } from '../../types/category';
import cn from 'classnames';

interface Props {
  categories: Category[];
}

export const CategoriesList: React.FC<Props> = ({ categories }) => {
  
  return (
    <p className="panel-tabs has-text-weight-bold">
      <a
        href="#/"
      >
        All
      </a>


      {categories.map((category) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          key={category.id}
          className={cn({
            'is-active': false,
          })}
        >
          {category.title}
        </a>
      ))}
    </p>
)};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    propertyId: 1,
    id: 1,
    title: 'Optimized Editable List',
    url: 'https://react-env.com/components/optimized-input/',
  },
  {
    propertyId: 1,
    id: 2,
    title: 'Fetch User List',
    url: 'https://react-env.com/components/fetch-user-list/',
  },
  {
    propertyId: 1,
    id: 3,
    title: 'Using React Context',
    url: 'https://react-env.com/components/react-context/',
  },
  {
    propertyId: 1,
    id: 4,
    title: 'Dropdown List',
    url: 'https://#',
  },
];

import React from 'react';
import { FullApp } from '../../types/application';

interface Props {
  applications: FullApp[];
}

export const ApplicationsList: React.FC<Props> = ({ applications }) => {
  
  return (
    <div className="box table-container">
      <p data-cy="NoMatchingMessage">
        No aplications matching selected criteria
      </p>

      <table
        className="table is-striped is-narrow is-fullwidth"
      >
        <thead>
          <tr>
            <th>
              <span className="is-flex is-flex-wrap-nowrap">
                ID

                <a href="#/">
                  <span className="icon">
                    <i data-cy="SortIcon" className="fas fa-sort" />
                  </span>
                </a>
              </span>
            </th>

            <th>
              <span className="is-flex is-flex-wrap-nowrap">
                Application title

                <a href="#/">
                  <span className="icon">
                    <i className="fas fa-sort-down" />
                  </span>
                </a>
              </span>
            </th>

            <th>
              <span className="is-flex is-flex-wrap-nowrap">
                Component Property

                <a href="#/">
                  <span className="icon">
                    <i className="fas fa-sort-up" />
                  </span>
                </a>
              </span>
            </th>

            <th>
              <span className="is-flex is-flex-wrap-nowrap">
                Link

                <a href="#/">
                  <span className="icon">
                    <i className="fas fa-sort" />
                  </span>
                </a>
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          {applications.map((app) => 
          <tr key={app.id}>
            <td className="has-text-weight-bold">
              {app.id}
            </td>

            <td>{app.title}</td>
            <td>{app.property?.title}</td>

            <td className="has-text-link">
              <a href={app.url}>link</a>
            </td>
          </tr>
        )}
        </tbody> 
      </table>
    </div>
)};

